import React from "react";

const Info = (props) => {
  return (
    <div className="infoz">
      <div className="infoztext">
        <h2 className="infoh2">
          {props.response[2].displayName.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className="infonumb">
          {props.response !== null ? props.response[2].jackpotValue : "RON"}

          <span> GHC</span>
        </p>
      </div>
      <div className="infoztext">
        <h2 className="infoh2">
          {props.response[0].displayName.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className="infonumb">
          {props.response !== null ? props.response[0].jackpotValue : "RON"}

          <span> GHC</span>
        </p>
      </div>
      <div className="infoztext">
        <h2 className="infoh2">
          {props.response[4].displayName.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className="infonumb">
          {props.response !== null ? props.response[4].jackpotValue : "RON"}

          <span> GHC</span>
        </p>
      </div>
      <div className="infoztext">
        <h2 className="infoh2">
          {props.response[1].displayName.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className="infonumb">
          {props.response !== null ? props.response[1].jackpotValue : "RON"}

          <span> GHC</span>
        </p>
      </div>
      <div className="infoztext">
        <h2 className="infoh2">
          {props.response[3].displayName.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className="infonumb">
          {props.response !== null ? props.response[3].jackpotValue : "RON"}

          <span> GHC</span>
        </p>
      </div>
    </div>
  );
};

export default Info;
