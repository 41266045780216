import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";

const CarouselComp = () => {
  let resetTimeout;
  const carouselRef = useRef(null);

  let items = [
    { url: "./sc1.jpg" },
    { url: "./sc2.jpg" },
    { url: "./sc3.jpg" },
    { url: "./sc4.jpg" },
    { url: "./sc5.jpg" },
    { url: "./sc6.jpg" },
    { url: "./sc7.jpg" },
    { url: "./sc8.jpg" },
    { url: "./sc9.jpg" },
    { url: "./sc10.jpg" },
    { url: "./sc11.jpg" },
    { url: "./sc12.jpg" },
  ];

  return (
    <div className="carousel_container">
      <Carousel
        className="carousel"
        showArrows={false}
        pagination={false}
        itemsToShow={1}
        ref={carouselRef}
        itemsToScroll={1}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
        onNextEnd={({ index }) => {
          clearTimeout(resetTimeout);
          if (index + 1 === items.length) {
            if (carouselRef.current.goTo) {
              resetTimeout = setTimeout(() => {
                if (carouselRef.current.goTo) {
                  carouselRef.current.goTo(0);
                }
              }, 5000);
            }
          }
        }}
      >
        {items.map((item, key) => {
          return (
            <img src={item.url} alt="" className="carousel_image" key={key} />
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselComp;
