import logo from "./logo.svg";
import "./App.css";
import CarouselComp from "./CarouselComp";
import Info from "./Info";
import { useEffect, useState } from "react";
import axios from "axios";
function App() {
  const [jack, setJack] = useState(null);
  useEffect(() => {
    GetJackpots(setJack);
  }, []);

  const GetJackpots = async (setter) => {
    await axios
      .get("https://ghn-legacywebapi.syncrobet.ro/LegacySports/misc/jackpots")
      .then((response) => setter(response.data));
  };

  return (
    jack && (
      <div className="App">
        <div className="p_nav">
          <a
            className="p_nav_p"
            href="https://syncrogames.com/"
            target="_blank"
          >
            <img src="./socca.png" alt="" className="p_logo" />
          </a>
        </div>
        <CarouselComp />
        <Info response={jack} />
      </div>
    )
  );
}

export default App;
